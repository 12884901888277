<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- Export Modal -->
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
          type="button"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>
    <div class="manage-accounting my-3" style="padding: 0.4em">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <div
          class="menu d-flex flex-wrap align-items-center justify-content-between my-3"
        >
          <div class="d-flex align-items-center">
            <p class="m-0 me-3">
              {{ $t("depreciation.info_date") }}
            </p>
            <!-- <date-picker
              class="inline-block h-full"
              locale="en"
              v-model="infoDate"
              :masks="masks"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div
                  class="input-arrow me-3 my-1 d-flex align-items-center"
                  @click="togglePopover"
                >
                  {{ inputValue }}
                </div>
              </template>
            </date-picker> -->
            <select
              class="form-control w-auto me-3 my-1"
              v-model="infoYear"
              @change="loadAccNote"
            >
              <option
                v-for="year in yearList"
                :key="year.value"
                :value="year.value"
                >{{ year.label }}</option
              >
            </select>
            <Button class="refresh" icon="pi pi-refresh" @click="loadAccNote" :loading="isLoading" />
          </div>
          <div class="d-flex align-items-center flex-wrap">
            <button
              v-if="!isNativeApp"
              class="btn-outline primary-btn me-3 d-flex my-1"
              @click="$bvModal.show('export-modal')"
            >
              <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
              <p>{{ $t("depreciation.export_file") }}</p>
            </button>
          </div>
        </div>
        <h4>
          Property, plant, and equipment
        </h4>
        <div class="statement-container">
          <div class="statement">
            <div class="statement-row statement-row-header">
              <div>{{ $t("depreciation.name") }}</div>
              <div>{{ $t("depreciation.prior") }}</div>
              <div>{{ $t("depreciation.increase") }}</div>
              <div>{{ $t("depreciation.decrease") }}</div>
              <div>{{ $t("depreciation.total") }}</div>
            </div>
            <div class="statement-row statement-row-title">
              <div>{{ $t("depreciation.cost") }}</div>
            </div>
            <div
              class="statement-row"
              v-for="(item, i) of tangible.cost"
              :key="i"
            >
              <div>
                {{ item.name }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.incoming) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.increase) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.decrease) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.outcoming) }}
              </div>
            </div>
            <div class="statement-row statement-row-title">
              <div>{{ $t("depreciation.total_cost") }}</div>
              <div class="text-end">
                {{ toShowAccountingPrice(tangible.total_cost_incoming) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(tangible.total_cost_increase) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(tangible.total_cost_decrease) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(tangible.total_cost_outcoming) }}
              </div>
            </div>
            <div class="statement-row statement-row-title">
              <div>Accumulated depreciation</div>
            </div>
            <div
              class="statement-row"
              v-for="(item, i) of tangible.depreciation"
              :key="i"
            >
              <div>
                {{ item.name }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.incoming) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.increase) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.decrease) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.outcoming) }}
              </div>
            </div>
            <div class="statement-row statement-row-title">
              <div>{{ $t("depreciation.total_acc_depreciation") }}</div>
              <div class="text-end">
                {{ toShowAccountingPrice(tangible.total_depreciation_incoming) }}
              </div>
              <div class="text-end">
                {{
                  toShowAccountingPrice(tangible.total_depreciation_increase)
                }}
              </div>
              <div class="text-end">
                {{
                  toShowAccountingPrice(tangible.total_depreciation_decrease)
                }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(tangible.total_depreciation_outcoming) }}
              </div>
            </div>
            <div class="statement-row statement-row-header">
              <div>{{ $t("depreciation.net_ppe") }}</div>
              <div class="text-end">
                {{ toShowAccountingPrice(tangible.net_incoming) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(tangible.net_increase) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(tangible.net_decrease) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(tangible.net_outcoming) }}
              </div>
            </div>
          </div>
        </div>
        <h4>
          {{ $t("depreciation.intangible_assets") }}
        </h4>
        <div class="statement-container">
          <div class="statement">
            <div class="statement-row statement-row-header">
              <div>{{ $t("depreciation.name") }}</div>
              <div>{{ $t("depreciation.prior") }}</div>
              <div>{{ $t("depreciation.increase") }}</div>
              <div>{{ $t("depreciation.decrease") }}</div>
              <div>{{ $t("depreciation.total") }}</div>
            </div>
            <div class="statement-row statement-row-title">
              <div>{{ $t("depreciation.cost") }}</div>
            </div>
            <div
              class="statement-row"
              v-for="(item, i) of intangible.cost"
              :key="i"
            >
            <div>
                {{ item.name }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.incoming) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.increase) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.decrease) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.outcoming) }}
              </div>
            </div>
            <div class="statement-row statement-row-title">
              <div>{{ $t("depreciation.total_cost") }}</div>
              <div class="text-end">
                {{ toShowAccountingPrice(intangible.total_cost_incoming) }}
              </div>
              <div class="text-end">
                {{
                  toShowAccountingPrice(intangible.total_cost_increase)
                }}
              </div>
              <div class="text-end">
                {{
                  toShowAccountingPrice(intangible.total_cost_decrease)
                }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(intangible.total_cost_outcoming) }}
              </div>
            </div>
            <div class="statement-row statement-row-title">
              <div>{{ $t("depreciation.amortization") }}</div>
            </div>
            <div
              class="statement-row"
              v-for="(item, i) of intangible.depreciation"
              :key="i"
            >
              <div>
                {{ item.name }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.incoming) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.increase) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.decrease) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(item.outcoming) }}
              </div>
            </div>
            <div class="statement-row statement-row-title">
              <div>{{ $t("depreciation.total_amortization") }}</div>
              <div class="text-end">
                {{ toShowAccountingPrice(intangible.total_depreciation_incoming) }}
              </div>
              <div class="text-end">
                {{
                  toShowAccountingPrice(intangible.total_depreciation_increase)
                }}
              </div>
              <div class="text-end">
                {{
                  toShowAccountingPrice(intangible.total_depreciation_decrease)
                }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(intangible.total_depreciation_outcoming) }}
              </div>
            </div>
            <div class="statement-row statement-row-header">
              <div>{{ $t("depreciation.net_intangible_assets") }}</div>
              <div class="text-end">
                {{ toShowAccountingPrice(intangible.net_incoming) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(intangible.net_increase) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(intangible.net_decrease) }}
              </div>
              <div class="text-end">
                {{ toShowAccountingPrice(intangible.net_outcoming) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import { authHeader } from "../../store/actions";
import Loading from "vue-loading-overlay";
import moment from "moment";
import { mapGetters } from "vuex";
import baseUrl from "../../util/backend";
// import DatePicker from "v-calendar/lib/components/date-picker.umd";

import { toShowAccountingPrice } from "../../util/accountingPrice";
export default {
  name: "AccountingNote",
  components: {
    Loading,
    // DatePicker,
  },
  data() {
    return {
      accounting_period: moment().startOf('year'),
      isLoading: false,
      company_id: null,
      infoDate: moment().format(),
      masks: {
        input: "DD/MM/YYYY",
      },
      infoYear: new Date().getFullYear(),
      yearList: [],
      lastAccountingMonth: 12,
      lastAccountingYear: new Date().getFullYear(),
      tangible: {
        total_cost_incoming: 0,
        total_cost_increase: 0,
        total_cost_decrease: 0,
        total_cost_outcoming: 0,
        total_depreciation_incoming: 0,
        total_depreciation_increase: 0,
        total_depreciation_decrease: 0,
        total_depreciation_outcoming: 0,
        net_incoming: 0,
        net_increase: 0,
        net_decrease: 0,
        net_outcoming: 0,
        cost: [],
        depreciation: [],
      },
      intangible: {
        total_cost_incoming: 0,
        total_cost_increase: 0,
        total_cost_decrease: 0,
        total_cost_outcoming: 0,
        total_depreciation_incoming: 0,
        total_depreciation_increase: 0,
        total_depreciation_decrease: 0,
        total_depreciation_outcoming: 0,
        cost: [],
        depreciation: [],
      },
      accountingData: {
        costTangible: [],
        accTangible: [],
        costIntangible: [],
        accIntangible: [],
      },
      totalTangible: { costs: {}, accDepreciation: {}, net: {} },
      totalIntangible: { costs: {}, accDepreciation: {}, net: {} },
    };
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      companyName: "companyName",
    }),
  },
  watch: {
    // infoDate() {
    //   this.loadAccNote();
    // },
  },
  methods: {
    toShowAccountingPrice,
    async onExport(isMail) {
      this.isLoading = true;
      try {
        const filename = `${this.$t("depreciation.financial_note")}_${
          this.companyName
        }_${moment().format("DD-MM-YYYY_HH-mm")}.xlsx`;
        const res = await axios.get(
          `${baseUrl()}depreciation/export/financial/${this.company_id}`,
          {
            params: {
              period_date: this.infoYear,
            },
            ...authHeader(),
            responseType: "blob",
          }
        );
        if (!isMail) {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          const elink = document.createElement("a");
          elink.download = filename;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },

    async loadAccNote() {
      this.isLoading = true;
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      this.lastAccountingYear =
        this.lastAccountingMonth !== 11 ? this.infoYear + 1 : this.infoYear;
      try {
        const res = await axios.get(
          `${baseUrl()}depreciation/financial/${this.company_id}`,
          {
            params: {
              period_date: this.infoYear,
            },
            ...authHeader(),
          }
        );
        this.tangible = res.data.tangible_and_non_dp
        this.intangible = res.data.intangible
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.isLoading = false;
    },
    setYearList() {
      const accountingYear = this.accountYear;
      const list = [];
      const accountingMonth = this.accountMonth - 1;
      const currentYear = new Date().getFullYear();
      if (accountingMonth > 0) {
        let index = 0;
        while (this.accounting_period.get('year') <= currentYear) {
          if (
            moment().isSameOrAfter(
              moment(
                [accountingYear + index, accountingMonth + 1],
                "YYYY-M"
              ).startOf("M")
            )
          ) {
            list.push({
              label: `${this.$t(
                `adddate.month_short[${accountingMonth}]`
              )} ${accountingYear + index} - ${this.$t(
                `adddate.month_short[${accountingMonth - 1}]`
              )} ${accountingYear + 1 + index}`,
              value: this.accounting_period.format('YYYY-MM-DD'),
            });
          }
          index += 1;
          this.accounting_period.add(1, 'year')
        }
      } else {
        let index = 0;
        while (this.accounting_period.get('year') <= currentYear) {
          list.push({
            label: `${this.$t(
              `adddate.month_short[${accountingMonth}]`
            )} ${accountingYear + index} - ${this.$t(
              `adddate.month_short[11]`
            )} ${accountingYear + index}`,
            value: this.accounting_period.format('YYYY-MM-DD'),
          });
          index += 1;
          this.accounting_period.add(1, 'year')
        }
      }
      this.yearList = list;
      this.infoYear = list[list.length - 1].value;
    },

    async getCompanyData() {
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(
          `${baseUrl()}company/getCompany/${this.company_id}`,
          authHeader()
        );
        this.accounting_period = moment(res.data.data.accounting_period)
        this.accountMonth = moment(res.data.data.accounting_period).isValid()
          ? +moment(res.data.data.accounting_period).format("M")
          : 1;
        this.accountYear = moment(res.data.data.accounting_period).isValid()
          ? +moment(res.data.data.accounting_period).format("YYYY")
          : new Date().getFullYear();
        this.setYearList();
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
    },
  },
  async mounted() {
    await this.getCompanyData();
    this.loadAccNote();
  },
};
</script>

<style lang="scss" scoped>
input,
select {
  height: 45px;
}

.manage-accounting {
  text-align: left;
}

.company-name {
  font-size: 18px;
}

.btn-outline {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }

  &:disabled {
    color: #DDDDDD !important;
    border-color: #DDDDDD !important;
    cursor: not-allowed !important;
  }
}

.form-control {
  min-width: 200px;
  width: 200px;
}

.search-box {
  input {
    width: 200px;
    background-color: transparent !important;
    border-radius: 10px;
  }
  img {
    margin: 0 10px;
  }
  background-color: #e0e0e0 !important;
}

.input-arrow {
  user-select: none;
  cursor: pointer;
  min-width: 200px;
  width: 200px;
  height: 45px;
  background-color: #f4f4f4;
  padding: 0.375rem 0.75rem;
  color: #212529;
  border-radius: 0.25rem;
  background-image: url("../../assets/down-arrow-black.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 97% 50% !important;
  padding-right: 40px !important;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.confirm-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  border-radius: 10px;
  border: none;

  &-red {
    background: #ed1616;
  }
}

.cancel-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: #888;
  border-radius: 10px;
  border: none;
}

.statement-container {
  width: 100%;
  overflow-x: scroll;
  margin: 0 0 2rem 0;
}

.statement {
  width: 1800px;
}

.statement-row {
  display: grid;
  grid-template-columns: 30% repeat(4, 17.5%);
  /* grid-gap: 10px; */
  /* background-color: #2196f3; */
  padding: 10px;
  font-size: 14px;

  &-header,
  &-title,
  &-summary {
    font-weight: 700;
    font-size: 16px;
  }

  &-header {
    background: linear-gradient(0deg, #045ebd 0%, #007afe 100%);
    color: #fff;
    text-align: center;
  }

  &-summary {
    background: #ededed;
  }
}

/*
  Animation
*/
</style>
